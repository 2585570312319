import { Form, Formik, useField } from "formik";
import { MouseEventHandler, ReactElement } from "react";
import FormikSubmitHandler from "../../types/FormikSubmitHandler";
import Button from "../Button/Button.component";
import Card from "../Card/Card.component";
import Padding from "../Padding/Padding.component";
import Row from "../Row/Row.component";
import Text from "../Text/Text.component";
import * as yup from "yup";
import * as S from "./CommentComposer.styled";
import Margin from "../Margin/Margin.component";
import { colors } from "../../Theme";

export interface CommentComposerProps {
  placeholder?: string;
  name: string;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onSubmit: FormikSubmitHandler<CommentComposerInputValues>;
  active?: boolean;
  disabled?: boolean;
  submitText?: string;
  username?: string;
}

export interface CommentComposerInputValues {
  [name: string]: string;
}

const commentValidationSchema = (name: string) =>
  yup.object().shape({
    [name]: yup.string().required("You wanna write something first, no?"),
  });

export default function CommentComposer({
  name,
  active,
  username,
  placeholder,
  onSubmit,
  onCancel,
  submitText,
  disabled,
}: CommentComposerProps): ReactElement {
  const initialValue: CommentComposerInputValues = {
    [name]: "",
  };

  return (
    <Card>
      {username && (
        <Padding bottom={4}>
          <Row>
            <Text color={colors.lightGrey} small>
              comment as @{username}
            </Text>
          </Row>
        </Padding>
      )}
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={commentValidationSchema(name)}
      >
        {({ errors }) => (
          <Form>
            <CommentInputField
              placeholder={placeholder}
              name={name}
              disabled={disabled}
            />
            <Margin top={10} />
            <Row reverse spaceBetween>
              <Row>
                <Padding>
                  {onCancel && (
                    <Button color="dark" onClick={onCancel} disabled={disabled}>
                      cancel
                    </Button>
                  )}
                </Padding>
                <Padding left={8}>
                  <Button color="dark" type="submit" disabled={disabled}>
                    {submitText ?? "comment"}
                  </Button>
                </Padding>
              </Row>
              {errors[name] && (
                <Text small color="red">
                  {errors[name]}
                </Text>
              )}
            </Row>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

interface CommentInputFieldProps {
  name: string;
  placeholder?: string;
  disabled?: boolean;
}

function CommentInputField({
  name,
  placeholder,
  disabled,
}: CommentInputFieldProps): ReactElement {
  const [field] = useField(name);
  return (
    <Row>
      <S.TextArea {...field} placeholder={placeholder} disabled={disabled} />
    </Row>
  );
}
