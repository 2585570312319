import styled, { css } from "styled-components";
import { MarginProps } from "./Margin.component";

export const Margin = styled.div<MarginProps>`
  display: flex;
  flex-direction: column;
  ${({ all }) =>
    all &&
    css`
      margin: ${all}px ${all}px ${all}px ${all}px;
    `}

  ${({ top }) =>
    top &&
    css`
      margin-top: ${top}px;
    `}

    ${({ bottom }) =>
    bottom &&
    css`
      margin-bottom: ${bottom}px;
    `}

    ${({ left }) =>
    left &&
    css`
      margin-left: ${left}px;
    `}

    ${({ right }) =>
    right &&
    css`
      margin-right: ${right}px;
    `}
`;
