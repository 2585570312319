import { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppBar from "../../components/AppBar/AppBar.component";
import Layout from "../../components/Layout/Layout.component";
import Loader from "../../components/Loader.component";
import Margin from "../../components/Margin/Margin.component";
import Post from "../../components/Post/Post.component";
import IComment from "../../types/Comment.type";
import { IPost } from "../../types/Post.type";
import backendApi from "../../api/backendApi";
import { NotificationContext } from "../../providers/NotificationProvider";
import CommentComposer from "../../components/CommentComposer/CommentComposer.component";
import Column from "../../components/Column/Column.component";
import Comment from "../../components/Comment/Comment.component";
import { AuthenticationContext } from "../../providers/AuthenticationProvider";
import { Helmet } from 'react-helmet'


interface Params {
  postId: string;
}

function PostPage(): ReactElement {
  const { postId } = useParams<Params>();
  const { showSnackbar } = useContext(NotificationContext);
  const { user, showSignUpModal } = useContext(AuthenticationContext);
  const [comments, setComments] = useState<Array<IComment>>();
  const [post, setPost] = useState<IPost>();
  const [isPostLoaded, setPostLoaded] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchPost() {
      try {
        const loadedPost = await backendApi.pocketBase.getPostByd(postId);
        setPost({ commentCount: post?.commentCount, ...loadedPost });
        setPostLoaded(true);
      } catch (error: any) {
        showSnackbar({ children: error.message, type: "error" });
      }
    }

    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchComments() {
      if (!isPostLoaded) {
        return;
      }
      try {
        const comments = await backendApi.pocketBase.getComments(postId);
        setComments(comments);
        if (post) {
          const updatedPost = { ...post, commentCount: comments.length };
          setPost(updatedPost);
        }
      } catch (error: any) {
        showSnackbar({ children: error.message, type: "error" });
      }
    }

    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostLoaded]);

  return (
    <>
      <Helmet>
        <title> ProductMeme | {post?.title} </title>
        <meta name="description" content={`Check out this meme! ${post?.title}` || post?.description} />
      </Helmet>
      <AppBar />
      <Layout>
        {post ? <Post {...post} /> : <Loader />}
        <Margin top={10} />
        <CommentComposer
          disabled={isLoading ? true : false}
          username={user.username}
          name="commentInput"
          placeholder={"Write a comment..."}
          onSubmit={async (values, actions) => {
            if (user.isAuthenticated) {
              setLoading(true);
              await createCommentAndUpdateUI({
                postId,
                userId: user.id,
                content: values.commentInput,
              });
              actions.resetForm();
              setLoading(false);
            } else {
              showSignUpModal();
            }
          }}
        />
        {comments == null ? (
          <Loader />
        ) : (
          comments && buildCommentList(formatData(comments, "root"))
        )}
      </Layout>
    </>
  );

  async function createCommentAndUpdateUI({
    userId,
    postId,
    content,
    parentCommentId,
  }: {
    userId: string;
    postId: string;
    content: string;
    parentCommentId?: string;
  }) {
    try {
      const comment: IComment = {
        author: user,
        postId,
        content,
        parentCommentId,
      };
      await backendApi.pocketBase.createComment(comment);
      if (comments) setComments([comment, ...comments]);
      else setComments([comment]);
    } catch (error: any) {
      handleError(error);
    }
  }

  function handleError(error: any) {
    if (error.message) {
      showSnackbar({ children: error.message, type: "error" });
    } else {
      showSnackbar({
        children: "Something went wrong",
        type: "error",
      });
    }
  }

  function buildCommentList(comments: Array<IComment>) {
    return comments.map((comment) => {
      return (
        <Column>
          <Margin top={10} />
          <Comment
            {...comment}
            depth={comment.depth!}
            id={comment.id!}
            key={comment.id}
            name="commentInput"
            onSubmit={async (values, actions) => {
              if (user.isAuthenticated) {
                setLoading(true);
                await createCommentAndUpdateUI({
                  postId: postId,
                  userId: user.id,
                  content: values.commentInput,
                  parentCommentId: comment.id,
                });
                actions.resetForm();
                setLoading(false);
              } else {
                showSignUpModal();
              }
            }}
            children={comment.replies && buildCommentList(comment.replies)}
          />
        </Column>
        // </Margin>
      );
    });
  }

  function formatData(data: Array<IComment>, root: string) {
    const temp: any = {};

    data.forEach((comment: IComment) => {
      let parentCommentId;
      if (
        comment.parentCommentId === "" ||
        comment.parentCommentId === undefined
      ) {
        parentCommentId = root;
      } else {
        parentCommentId = comment.parentCommentId;
      }

      // Set the depth property based on the parent comment's depth
      comment.depth = temp[parentCommentId]?.depth + 1 || 0;

      if (temp[parentCommentId] == null) {
        temp[parentCommentId] = {};
      }

      if (temp[parentCommentId].replies == null) {
        temp[parentCommentId].replies = [];
      }

      if (temp[comment.id!] == null) {
        temp[parentCommentId].replies.push(
          Object.assign((temp[comment.id!] = {}), comment)
        );
      } else {
        temp[parentCommentId].replies.push(
          Object.assign(temp[comment.id!], comment)
        );
      }
    });

    return temp[root]?.replies ?? [];
  }
}

export default PostPage;
