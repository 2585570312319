import { Form, Formik } from "formik";
import { ReactElement, useContext, useEffect } from "react";
import * as Yup from "yup";
import AppBar from "../../components/AppBar/AppBar.component";
import Card from "../../components/Card/Card.component";
import Layout from "../../components/Layout/Layout.component";
import styled from "../../Theme";
import Text from "../../components/Text/Text.component";
import { AuthenticationContext } from "../../providers/AuthenticationProvider";
import getBaseUrl from "../../api/util/getBaseUrl";
import Button from "../../components/Button/Button.component";
import { NotificationContext } from "../../providers/NotificationProvider";
import Row from "../../components/Row/Row.component";
import PostEditable from "../../components/PostEditable/PostEditable.component";
import backendApi from "../../api/backendApi";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export default function PostCreationPage(): ReactElement {
  const { user, showSignUpModal, closeModal } = useContext(
    AuthenticationContext
  );
  const { showSnackbar } = useContext(NotificationContext);

  function goBackToHomePage() {
    const baseUrl = getBaseUrl();
    window.location.href = baseUrl.frontend ?? "/";
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    contentUrl: Yup.string()
      .trim()
      .matches(
        /(https?:\/\/.*\.(?:png|jpg|webp|jpeg))/i,
        "There seems to be an issue with this link"
      ),
  });

  // this is to ensure that non-authenticated users cannot create posts
  useEffect(() => {
    if (user.isAuthenticated !== true) {
      showSignUpModal({
        backdropClick: () => goBackToHomePage(),
      });
    } else {
      closeModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <AppBar />
      <Layout>
        <Text h1> Create a post</Text>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            title: "",
            contentUrl: "",
            description: "",
            uploadedImage: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (
            { title, description, contentUrl, uploadedImage },
            { validateForm, resetForm }
          ) => {
            validateForm();

            const formData = convertFieldValuesToFormDate(
              title,
              description,
              contentUrl,
              user.id,
              uploadedImage
            );

            try {
              await backendApi.pocketBase.createPost(formData);

              resetForm();
              showSnackbar({
                children: "Your post has been successfully submitted!",
                type: "info",
              });
            } catch (error: any) {
              showSnackbar({
                children:
                  error.message ??
                  "Sry, something went wrong. It's probably us. Maybe you but probably us.",
                type: "error",
              });
            }
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <StyledForm>
              <Card>
                <PostEditable author={user.username} setField={setFieldValue} />

                <Row reverse>
                  <Button type="submit">Submit</Button>
                </Row>
              </Card>
            </StyledForm>
          )}
        </Formik>
      </Layout>
    </>
  );

  function convertFieldValuesToFormDate(
    title: string,
    description: string,
    contentUrl: string,
    userId: string,
    uploadedImage: File | any
  ) {
    const formData = new FormData();

    formData.append("title", title);
    formData.append("description", description);
    formData.append("contentUrl", contentUrl);
    formData.append("author", userId);
    formData.append("file", uploadedImage);

    return formData;
  }
}
