import { IPost } from "../../types/Post.type";
import getBaseUrl, { IBaseUrl } from "../util/getBaseUrl";
// @ts-ignore
import PocketBase, { User } from "pocketbase";
import IComment from "../../types/Comment.type";
import { IArticle } from "../../types/Article.type";

class PocketBaseClient {
  private baseUrl: IBaseUrl;
  private static instance: PocketBaseClient;
  private pocketBase: PocketBase;

  private constructor() {
    this.baseUrl = getBaseUrl();
    this.pocketBase = new PocketBase(this.baseUrl.pocketBase);
  }

  public static getInstance() {
    if (!PocketBaseClient.instance) {
      PocketBaseClient.instance = new PocketBaseClient();
    }

    return PocketBaseClient.instance;
  }

  //posts
  async getPostByd(id: string): Promise<IPost> {
    try {
      const response: any = await this.pocketBase
        .collection("posts")
        .getOne(id);
      const post: IPost = response;
      return post;
    } catch (error) {
      throw error;
    }
  }

  async getPosts(): Promise<Array<IPost>> {
    try {
      const response = await this.pocketBase
        .collection("posts")
        .getFullList(undefined, {
          sort: "-created",
          expand: 'comments(postId)'
        });


      const posts = response.map((item: any) => {
        const post: IPost = {
          commentCount: item.expand["comments(postId)"]?.length ?? 0,
          ...item
        };
        return post;
      });

      return posts;
    } catch (error) {
      throw error;
    }
  }

  async createPost(post: FormData): Promise<any> {
    try {
      const createdRecord = await this.pocketBase
        .collection("posts")
        .create(post);
      return createdRecord;
    } catch (error) {
      throw error;
    }
  }

  async updatePost(id: string, formData: FormData) {
    try {
      const record = await this.pocketBase
        .collection("posts")
        .update(id, formData);
      return record;
    } catch (error) {
      throw error;
    }
  }

  // articles

  async getArticles() {
    try {
      const response = await this.pocketBase
        .collection("articles")
        .getFullList(undefined, {
          sort: "-created",
        });

      const articles = response.map((item: any) => {
        const post: IArticle = item;
        return post;
      });

      return articles;
    } catch (error) {
      throw error;
    }
  }

  async getArticleBySlug(slug: string) {
    try {
      const response: any = await this.pocketBase
        .collection("articles")
        .getList(1, 1, {
          filter: `slug="${slug}"`,
        });
      const article: IArticle = response.items[0];
      return article;
    } catch (error) {
      throw error;
    }
  }

  // comments
  async getComments(
    postId: string,
    offset?: number,
    count?: number
  ): Promise<Array<IComment>> {

    try {
      const response = await this.pocketBase
        .collection("comments")
        .getList(offset, count, {
          filter: `postId = "${postId}"`,
          expand: 'author',
        });

      const comments = response.items.map((item: any) => {
        const comment: IComment = { ...item, author: item.expand['author'], };
        return comment;
      });

      return comments;
    } catch (error) {
      throw error;
    }
  }

  async createComment({ content, author, postId, parentCommentId, articleId }: IComment): Promise<void> {
    try {
      await this.pocketBase.collection("comments").create({
        content,
        author: author.id,
        postId,
        parentCommentId,
        articleId,
      });
    } catch (error) {
      throw error;
    }
  }

  // authentication
  async login(email: string, password: string) {
    try {
      const authData = await this.pocketBase
        .collection("users")
        .authWithPassword(email, password);
      return authData;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    this.pocketBase.authStore.clear();
  }

  async refreshAuthToken() {
    try {
      const authData = await this.pocketBase.collection("users").authRefresh();
      return authData;
    } catch (error) {
      throw error;
    }
  }

  async register(
    email: string,
    password: string,
    passwordConfirm: string,
    username: string
  ): Promise<User> {
    try {
      const authData = await this.pocketBase.collection("users").create({
        email,
        password,
        passwordConfirm,
        username,
      });
      return authData;
    } catch (error) {
      throw error;
    }
  }
}

export default PocketBaseClient;
