import styled, { colors } from "../../Theme";

export const DescriptionWrapper = styled.div`
  border: 1px solid ${colors.lightGrey};
  /* background-color: ${colors.black}; */
  /* border-radius: 5px 5px 5px 5px; */
  margin: 8px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.textStyles.fontColor.default};
  padding: 8px;
  text-align: center;
  padding: 8px;
  /* font-weight: 300; */
`;
