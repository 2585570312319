import styled, { css } from "styled-components";
import { ButtonProps } from "./Button.component";

export const Button = styled.button<ButtonProps>`
  background: ${({theme}) => theme.button.primary.backgroundColor};
  border-color: ${({theme}) => theme.button.primary.borderColor};
  border-radius: 3px;
  font-weight: bold;
  font-size: 1em;
  padding: 5px 10px 5px 10px;
  max-width: 100px;
  width: 100%;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: none;
    `}
`;
