import { ReactNode } from "react";
import * as S from "./Row.style";

export interface RowProps {
  reverse?: boolean;
  spaceBetween?: boolean;
  spaceEvenly?: boolean;
  center?: boolean;
  alignStart?: boolean;
  maxWidth?:boolean; 
  children: ReactNode;
}

export default function Row(props: RowProps) {
  return <S.Row {...props}>{props.children}</S.Row>;
}
