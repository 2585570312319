import { useField } from "formik";
import { ReactElement } from "react";
import HTMLInputType from "../../types/HTMLInput.type";
import Margin from "../Margin/Margin.component";
import ErrorField from "./ErrorField.component";
import * as S from "./TextInput.styled";

export interface InputFieldProps {
  name: string;
  label?: string;
  hintText?: string;
  isTouched?: boolean;
  type: HTMLInputType;
  errorMessage?: string;
  placeholder?: string;
}

function TextInput({
  name,
  label,
  type,
  hintText,
  errorMessage,
  isTouched,
  placeholder,
}: InputFieldProps): ReactElement {
  const [field] = useField(name);

  return (
    <S.TextInputWrapper>
      <S.TextInputLabel>{label}</S.TextInputLabel>
      {hintText && <S.TextInputHint>{hintText}</S.TextInputHint>}
      <S.TextInput type={type} {...field} placeholder={placeholder} />
      {errorMessage && isTouched ? (
        <ErrorField errorMessage={errorMessage} />
      ) : (
        <Margin top={16} />
      )}
    </S.TextInputWrapper>
  );
}

export default TextInput;
