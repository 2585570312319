import styled, { css } from "styled-components";
import { RowProps } from "./Row.component";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  ${({ reverse }: RowProps) =>
    reverse &&
    css`
      flex-direction: row-reverse;
    `}

  ${({ spaceBetween }: RowProps) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}

  ${({ center }: RowProps) =>
    center &&
    css`
      justify-content: center;
    `}

  ${({ spaceEvenly }: RowProps) =>
    spaceEvenly &&
    css`
      justify-content: space-evenly;
    `}


  ${({ alignStart }: RowProps) =>
    alignStart &&
    css`
      align-items: start;
    `}

  ${({ maxWidth }: RowProps) =>
    maxWidth &&
    css`
      width: 100%;
    `}
`;
