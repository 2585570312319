import styled from "styled-components";
import { CircleProps } from "./Circle.component";


export const Circle = styled.div<CircleProps>`
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    border-radius:  50%;
    background: ${({ color }) => color};
    display: flex;
    justify-content: center; 
    align-items: center; 
`;