import { ReactElement, useContext, useState } from "react";
import { AuthenticationContext } from "../../providers/AuthenticationProvider";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Row from "../../components/Row/Row.component";
import TextInput from "../../components/TextInput/TextInput.component";
import Button from "../../components/Button/Button.component";
import Margin from "../../components/Margin/Margin.component";
import Snackbar from "../../components/Snackbar/Snackbar.component";
import { useHistory } from "react-router-dom";

export interface AuthenticationFormProps {
  redirectTo?: string;
}

export default function RegistrationForm({
  redirectTo,
}: AuthenticationFormProps): ReactElement {
  const [globalError, setGlobalError] = useState();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { refreshAuthToken, register, login } = useContext(
    AuthenticationContext
  );
  /**
   * Alphanumeric string that may include _ and – having a length of 3 to 16 characters.
   * https://digitalfortress.tech/tricks/top-15-commonly-used-regex/
   */
  const userNameRegExp = RegExp(/^[a-z0-9_-]{3,16}$/igm);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Must be at least 8 characters"),
    password2: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Your passwords do not match."),
    userName: Yup.string()
      .required("Required")
      .min(3, "Must be at least 3 characters")
      .max(16, "Must be at maximum 16 characters")
      .matches(
        userNameRegExp,
        "Make sure the username does not include special characters apart from '_' or '-'"
      ),
  });
  return (
    <>
      <Formik
        initialValues={{ email: "", userName: "", password: "", password2: "" }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async ({ email, userName, password, password2 }) => {
          setLoading(true);
          try {
            await register(email, password, password2, userName);
            // after creating the account, we need to authenticate too.
            await login(email, password);
            await refreshAuthToken();
            history?.push(redirectTo ?? "/");
          } catch (error: any) {
            // this looks a bit weird tho
            if (error.data.data.email.message) {
              setGlobalError(error.data.data.email.message);
            } else {
              setGlobalError(error.message);
            }
          }
          setLoading(false);
        }}
      >
        {({ errors, touched }) => (
          <Form style={{ width: "100%", maxWidth: "450px" }}>
            <Row spaceEvenly>
              <TextInput
                name="email"
                type="email"
                label="E-mail"
                placeholder="example@email.com"
                isTouched={touched.email}
                errorMessage={errors.email}
              />
            </Row>

            <Row spaceEvenly>
              <TextInput
                name="userName"
                type="text"
                label="Username"
                placeholder="stephan_arbeit"
                isTouched={touched.userName}
                errorMessage={errors.userName}
              />
            </Row>

            <Row spaceEvenly>
              <TextInput
                name="password"
                type="password"
                label="Password"
                placeholder="password"
                isTouched={touched.password}
                errorMessage={errors.password}
              />
            </Row>

            <Row spaceEvenly>
              <TextInput
                name="password2"
                type="password"
                label="Repeat password"
                placeholder="password"
                isTouched={touched.password2}
                errorMessage={errors.password2}
              />
            </Row>
            <Row>
              <Button fullWidth disabled={isLoading} type="submit">
                {isLoading ? "Loading..." : "Submit"}
              </Button>
            </Row>
            <Margin top={20} />
          </Form>
        )}
      </Formik>
      <Snackbar
        isVisible={globalError!!}
        type="error"
        children={globalError}
        onClose={() => setGlobalError(undefined)}
      />
    </>
  );
}
