import styled, { css } from "styled-components";
import { colors } from "../../Theme";
import { MenuItemProps } from "./MenuItem.component";

export const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  color: ${(props) => props.theme.textStyles.fontColor.default};
  padding: ${(props) => props.theme.paddings.normal};

  ${({ active }: MenuItemProps) =>
    active &&
    css`
      font-weight: bold;
    `}

  :hover {
    color: ${(props) => colors.pink};
  }
`;

export const MenuItemContentWrapper = styled.div`
  display: flex;
  height: 100%;
`;
