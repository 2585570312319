import { createContext, ReactNode, useState } from "react";
import Snackbar, {
  SnackbarProps,
} from "../components/Snackbar/Snackbar.component";

interface NotificationContextProps {
  showSnackbar: (props: SnackbarProps) => void;
  closeSnackbar: () => void;
}

export const NotificationContext = createContext(
  {} as NotificationContextProps
);

interface NotificationProviderProps {
  children: ReactNode;
}

export function NotificationProvider({ children }: NotificationProviderProps) {
  const [snackbarConfig, setSnackbarConfig] = useState({} as SnackbarProps);

  function showSnackbar(snackbarData?: SnackbarProps) {
    setSnackbarConfig({ ...snackbarConfig, ...snackbarData, isVisible: true });
  }

  function closeSnackbar() {
    setSnackbarConfig({ ...snackbarConfig, isVisible: false });
  }

  return (
    <NotificationContext.Provider value={{ showSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        onClose={closeSnackbar}
        isVisible={snackbarConfig.isVisible}
        type={snackbarConfig.type}
      >
        {snackbarConfig.children}
      </Snackbar>
    </NotificationContext.Provider>
  );
}
