import styled from "styled-components";
import { Note } from "../Note/Note.styled";

export const Snackbar = styled(Note)`
  position: fixed; 
  margin: 8px;
  bottom: 10px; 
  width: 100%; 
  max-width: 300px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 1000;

  animation: pulse .3s; 

  @keyframes pulse {
    0% {
      transform:  translate(-50%, 100%)
    }
    60% {
      transform:  translate(-50%, -30%)
    }
    100% {
      transform:  translate(-50%, 0%)
    }
  } 
`;

export const CloseIcon = styled.div`
    cursor: pointer ;
`; 