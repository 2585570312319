import styled, { css } from "styled-components";
import { Card } from "../Card/Card.style";
import { NoteProps } from "./Note.component";

const warning = css`
  border-color: brown;
  background-color: orange;
`;

const error = css`
  border-color: red;
  background-color: pink;
`;

const info = css`
  border-color: blue;
  background-color: lightblue;
`;

export const Note = styled(Card)`
  ${({ type }: NoteProps) =>
    type &&
    css`
      ${type === "warning"
        ? warning
        : type === "error"
        ? error
        : type === "info"
        ? info
        : info};
    `}
`;
