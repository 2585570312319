import { ReactElement, useCallback, useEffect, useState } from "react";
import AppBar from "../../components/AppBar/AppBar.component";
import Post from "../../components/Post/Post.component";
import { IPost } from "../../types/Post.type";
import Layout from "../../components/Layout/Layout.component";
import Loader from "../../components/Loader.component";
import backendApi from "../../api/backendApi";
import Margin from "../../components/Margin/Margin.component";
import { Helmet } from "react-helmet";

function FeedPage(): ReactElement {
  return (
    <>
      <Helmet>
        <title> ProductMeme | Fun Tech and Product Memes </title>
        <meta name="description" content="The place to meme about products and the tech world" />
      </Helmet>
      <AppBar />
      <Layout>
        <Feed />
      </Layout>
    </>
  );
}

export default FeedPage;

function Feed(): ReactElement {
  const [data, setData] = useState<Array<IPost>>();

  useEffect(() => {
    async function fetchPosts() {
      const posts = await backendApi.pocketBase.getPosts();
      setData(posts);
    }

    fetchPosts();
  }, []);

  const memoizedFeed = useCallback(() => {
    return (
      data &&
      data.map((props) => (
        <Margin key={props.id} top={20} bottom={20}>
          <Post {...props} linkTo={`/post/${props.id}`} hoverable />
        </Margin>
      ))
    );
  }, [data]);

  if (!data) {
    return <Loader />;
  }

  return <>{memoizedFeed()}</>;
}
