import styled, { css } from "styled-components";
import { CardProps } from "./Card.component";

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid ${(props) => props.theme.card.borderColor};
  padding: ${(props) => props.theme.paddings.normal};
  background-color: ${(props) => props.theme.card.backgroundColor};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

    ${({ borderColor }) =>
    borderColor &&
    css`
      border-color: ${borderColor};
    `}


    ${({ hoverable }) =>
    hoverable &&
    css`
      :hover {
        border-color: ${(props) => props.theme.card.hover.borderColor};
      }
    `}
`;
