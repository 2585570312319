import { ReactElement } from "react";
import AppBar from "../../components/AppBar/AppBar.component";
import Layout from "../../components/Layout/Layout.component";

function AccountPage(): ReactElement {
  return (
    <>
      <AppBar />

      <Layout>
        <p> account page </p>
      </Layout>
    </>
  );
}

export default AccountPage;
