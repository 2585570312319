import styled from "styled-components";

export const DropdownMenuContainer = styled.div`
  margin-top: 5px;
  display: block;
  position: absolute;
  right: 0;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  width: 100%;
  min-width: 120px;
  border: 1px solid ${(props) => props.theme.card.borderColor};
  background-color: ${(props) => props.theme.card.backgroundColor};
`;

export const DropdownMenuItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  padding: ${(props) => props.theme.paddings.normal};

  :hover {
    background-color: ${(props) => props.theme.colors.lightPink};
  }
`;
