import { ReactElement, useContext, useEffect, useState } from "react";
import backendApi from "../../api/backendApi";
import AppBar from "../../components/AppBar/AppBar.component";
import Layout from "../../components/Layout/Layout.component";
import { NotificationContext } from "../../providers/NotificationProvider";
import { IArticle } from "../../types/Article.type";
import ArticleList from "./ArticleList.component";

function BlogPage(): ReactElement {
  const [articles, setArticles] = useState<IArticle[]>();
  const { showSnackbar } = useContext(NotificationContext);

  useEffect(() => {
    async function fetchArticles() {
      try {
        const articles = await backendApi.pocketBase.getArticles();
        setArticles(articles);
      } catch (error: any) {
        showSnackbar({ type: "error", children: `${error.message}` });
      }
    }

    fetchArticles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBar />

      <Layout>{articles && <ArticleList articles={articles} />}</Layout>
    </>
  );
}

export default BlogPage;
