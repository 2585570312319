import styled from "styled-components";
import { Card } from "../Card/Card.style";

export const Backdrop = styled.div`
    position: fixed;
    top: 0; 
    left: 0; 
    z-index: 998; 
    background-color: #00000087; 
    height: 100vh; 
    width: 100vw; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
`;

export const ModalContainer = styled.div`
    display: flex;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;
    z-index: 999;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px; 


    @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
        top: unset; 
        bottom: 0;
        transform: translate(-50%, 0px);
    }
`;

export const ModalHeaderWrapper = styled(Card)`
    width: 100%;
    z-index: 1;
    padding:  10px 0 10px 0;
    /* border: 2px solid; */
    margin-bottom: -5px;
    background: ${(props) => props.theme.colors.pink}
`;

