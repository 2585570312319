import { ReactElement } from "react";
import { IArticle } from "../../types/Article.type";
import ArticlePreview from "./ArticlePreview.component";

interface ArticleListProps {
  articles: Array<IArticle>;
}

function ArticleList({ articles }: ArticleListProps): ReactElement {
  return (
    <>
      {articles &&
        articles.map((article) => {
          return (
            <>
              <ArticlePreview {...article} key={article.id} />
            </>
          );
        })}
    </>
  );
}

export default ArticleList;
