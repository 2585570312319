import { ReactElement } from "react";
import Padding from "../Padding/Padding.component";
import Row from "../Row/Row.component";
import Text from "../Text/Text.component";
import * as S from "./Avatar.styled";

export default function AvatarImage({ src, alt }: AvatarProps): ReactElement {
  return <S.Avatar src={src} alt={alt} />;
}

export interface AvatarProps {
  text?: string;
  src?: string;
  alt?: string;
}

export function Avatar({ src, alt, text }: AvatarProps): ReactElement {
  return (
    <Row>
      <Padding right={text ? 8 : 0}>
        <AvatarImage src={src} alt={alt} />
      </Padding>
      <Text topMargin={false} bottomMargin={false}>
        {text}
      </Text>
    </Row>
  );
}
