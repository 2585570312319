import styled from "styled-components";

export const Wrapper = styled.div`
    padding-top: 60px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    background-color: ${(props) => props.theme.backgroundColor};
    `;


export const MiddleColumn = styled.div`
    max-width: 640px;
    width: 100%;
  `;

export const SideColumn = styled.div`
   flex: 1;
   max-width: 100%;
   min-height: 100vh; 
  `;

