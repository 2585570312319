import styled from "styled-components";
import { colors } from "../../Theme";

export const ImageUpload = styled.div`
  background-color: ${colors.lightGrey};
  height: 250px;
  width: 100%;

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;
    text-decoration-line: underline;
    height: 100%; 
    width: 100%; 
  }
`;
