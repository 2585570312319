import { ReactElement, useContext } from "react";
import { AuthenticationContext } from "../../providers/AuthenticationProvider";
import MenuItem, { MenuItemProps } from "../MenuItem/MenuItem.component";
import * as S from "./SideMenu.styled";
import { Smile, Book, Briefcase, LogOut, PlusCircle } from "react-feather";
import Column from "../Column/Column.component";

interface SideMenuSectionProps {
  label: string;
  items: MenuItemProps[];
}

interface SideMenuProps {
  backdropClick?: () => void;
}

export default function SideMenu({
  backdropClick,
}: SideMenuProps): ReactElement {
  const { logout, user, showSignUpModal: showModal } = useContext(AuthenticationContext);

  const sideMenuConfig: SideMenuSectionProps[] = [
    {
      label: "Menu",
      items: [
        { icon: <Smile />, label: "memes", linkTo: "/" },
        { icon: <Book />, label: "blog", linkTo: "/blog" },
        { icon: <Briefcase />, label: "jobs", linkTo: "/jobs" },
      ],
    },
    {
      label: "account",
      items: [
        { icon: <PlusCircle />, label: "create", linkTo: "/create" },
        {
          icon: <LogOut />,
          label: user.isAuthenticated ? "logout" : "sign in",
          linkTo: "",
          action: () =>
            user.isAuthenticated ? logout() : showModal({ child: <div> hello </div> }),
        },
      ],
    },
  ];

  return (
    <S.Backdrop onClick={() => backdropClick && backdropClick()}>
      <S.SideMenuContainer>
        <Column spaceBetween height="90%">
          {sideMenuConfig.map((section) => {
            return (
              <div>
                <S.SideMenuSection> {section.label}</S.SideMenuSection>
                {section.items.map((item) => (
                  <MenuItem {...item} />
                ))}
              </div>
            );
          })}
        </Column>
      </S.SideMenuContainer>
    </S.Backdrop>
  );
}
