import { ReactElement, ReactNode } from "react";
import * as S from "./Text.styled";

export interface TextProps {
  center ?: boolean;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  p?: boolean;
  small?: boolean;
  topMargin?: boolean;
  bottomMargin?: boolean;
  b?: boolean
  children: ReactNode;
  color?: string;
}

export default function Text({
  h1,
  h2,
  h3,
  h4,
  p,
  small,
  children,
  color,
  b,
  center,
  bottomMargin = true,
  topMargin = true,
}: TextProps): ReactElement {
  const styles = { color, topMargin, bottomMargin, b, center };

  if (h1) return <S.H1 {...styles} >{children}</S.H1>;
  if (h2) return <S.H2 {...styles} >{children}</S.H2>;
  if (h3) return <S.H3 {...styles} >{children}</S.H3>;
  if (h4) return <S.H4 {...styles} >{children}</S.H4>;
  if (p) return <S.p {...styles} >{children}</S.p>;
  if (small) return <S.Small {...styles} >{children}</S.Small>;

  return <S.p {...styles} >{children}</S.p>;
}
