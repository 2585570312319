import { TextProps } from "./Text.component"
import styled, { css } from "styled-components";

const textStyles = css`
    color: ${(props) => props.theme.textStyles.fontColor.default};
     ${({ color }: TextProps) => color && css`
        color: ${color};
     `}

    ${({ topMargin }: TextProps) =>
        topMargin !== true &&
        css`
          margin-block-start: 0;
        `}

    ${({ bottomMargin }: TextProps) =>
        bottomMargin !== true &&
        css`
          margin-block-end: 0;
        `}
    
    ${({ b }: TextProps) =>
        b &&
        css`
          font-weight: bold;
        `}


    ${({ center }: TextProps) =>
        center &&
        css`
          text-align: center;
        `}
`;

export const H1 = styled.h1`
    ${textStyles}
    /* font-size: ${(props) => props.theme.textStyles.fontSize.h1}; */
`;

export const H2 = styled.h2`
    ${textStyles}
    /* font-size: ${(props) => props.theme.textStyles.fontSize.h2}; */
`;

export const H3 = styled.h3`
    ${textStyles}
    /* font-size: ${(props) => props.theme.textStyles.fontSize.h3}; */
`;

export const H4 = styled.h4`
    ${textStyles}
    /* font-size: ${(props) => props.theme.textStyles.fontSize.h4}; */
`;

export const p = styled.p`
    ${textStyles}
    /* font-size: ${(props) => props.theme.textStyles.fontSize.p}; */
`;

export const Small = styled.small`
    ${textStyles}
    /* font-size: ${(props) => props.theme.textStyles.fontSize.small}; */
`;