import { ReactElement, ReactNode } from "react";
import * as S from "./Layout.styled";

interface LayoutProps {
  children?: ReactNode;
}

function Layout({ children }: LayoutProps): ReactElement {
  return (
    <S.Wrapper>
      <S.SideColumn />
      <S.MiddleColumn> {children} </S.MiddleColumn>
      <S.SideColumn />
    </S.Wrapper>
  );
}

export default Layout;
