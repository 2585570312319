import { ButtonHTMLAttributes, ReactElement } from "react";
import * as S from "./Button.styled";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean; 
}

export default function Button(props: ButtonProps): ReactElement {
  return <S.Button {...props}></S.Button>;
}
