import styled from "styled-components";
import { Card } from "../Card/Card.style";

export const ProfileButtonContainer = styled.div`
    position: relative; 
    display: inline-block;
`;

export const ProfileButton = styled(Card)`
    padding: 4px;
    cursor: pointer;  
`;
