import MenuItem, { MenuItemProps } from "../MenuItem/MenuItem.component";
import * as S from "./DropdownMenu.styled";

interface DropdownMenuProps {
  menuItems: MenuItemProps[];
}

export default function DropdownMenu({ menuItems }: DropdownMenuProps) {
  return (
    <S.DropdownMenuContainer>
      {menuItems.map((item) => {
        return <MenuItem {...item} />;
      })}
    </S.DropdownMenuContainer>
  );
}
