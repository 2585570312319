import { ReactElement, ReactNode } from "react";
import * as S from "./Column.styled";

export interface ColumnProps {
  children: ReactNode;
  spaceBetween?: boolean;
  height?: string; 
  flex?: number; 
}

export default function Column(props: ColumnProps): ReactElement {
  return <S.Column {...props}>{props.children}</S.Column>;
}
