import { ReactElement } from "react";
import UnstyledLink from "../Link/Link.component";
import Margin from "../Margin/Margin.component";
import * as S from "./MenuItem.styled";

export interface MenuItemProps {
  active?: boolean;
  icon?: ReactElement;
  label?: string;
  linkTo?: string;
  action?: () => void;
}

export default function MenuItem({
  icon,
  label,
  linkTo,
  action,
  active,
}: MenuItemProps) {
  return (
    <UnstyledLink to={linkTo}>
      <S.MenuItem onClick={action} active={active}>
        <S.MenuItemContentWrapper>
          {icon && <Margin right={label ? 8 : 0}>{icon}</Margin>}
          {label}
        </S.MenuItemContentWrapper>
      </S.MenuItem>
    </UnstyledLink>
  );
}
