import { ReactElement } from "react";
import Card from "../Card/Card.component";
import { ReactComponent as PepeSVG } from "../../static/svg/pepe.svg";
import { ReactComponent as WojakSVG } from "../../static/svg/wojak.svg";
import Row from "../Row/Row.component";
import Text from "../Text/Text.component";
import * as S from "./Modal.styled";
import Circle from "../Circle/Circle.component";
import LoginForm from "../../pages/SignUpPage/LoginForm.component";
import Divider from "../Divider/Divider.component";
import searchParams from "../../constants/searchParams";

export interface ModalProps {
  isVisible?: boolean;
  title?: string;
  description?: string;
  child?: ReactElement;
  backdropClick?: Function;
  actions?: Array<ReactElement>;
}

export default function SignUpModal({
  isVisible,
  backdropClick,
}: ModalProps): ReactElement {
  return !isVisible ? (
    <></>
  ) : (
    <>
      <S.ModalContainer>
        <Card>
          <Row spaceEvenly>
            <Circle color={"pink"} size={"60px"}>
              <PepeSVG />
            </Circle>
            <Text h1 center>
              Login
            </Text>
            <Circle color={"cadetblue"} size={"60px"}>
              <WojakSVG />
            </Circle>
          </Row>
          <Row center>
            <LoginForm />
          </Row>
          <Divider />
          <p style={{ textAlign: "center" }}>
            Do you have an account already?{" "}
            <a href={`/register?${searchParams.redirectTo}=${window.location.pathname}`}>
              <b>Register</b>
            </a>
          </p>
        </Card>
      </S.ModalContainer>
      <S.Backdrop onClick={() => backdropClick && backdropClick()} />
    </>
  );
}
