import { useField } from "formik";
import { ReactElement } from "react";
import Row from "../Row/Row.component";
import Text from "../Text/Text.component";
// import TextInput from "../TextInput/TextInput.component";
import * as S from "./PostEditable.styled";
import PostImage from "../Post/PostImage.component";
import ErrorField from "../TextInput/ErrorField.component";
import ImageUpload, {
  SetFieldFunction,
} from "../ImageUpload/ImageUpload.component";

import Margin from "../Margin/Margin.component";

interface EditablePostProps {
  author: string;
  setField: SetFieldFunction;
}

export default function PostEditable({
  author,
  setField,
}: EditablePostProps): ReactElement {
  return (
    <>
      <Row reverse>
        <Text small> posted by {author}</Text>
      </Row>

      <Title />

      <Image setField={setField} />

      <Description />
    </>
  );
}

function Title() {
  const [field, meta] = useField("title");
  const { error, touched } = meta;
  return (
    <>
      <S.TitleInput {...field} rows={1} placeholder="Enter a title" />
      {error && touched && <ErrorField errorMessage={error} />}
    </>
  );
}

function Image({ setField }: { setField: SetFieldFunction }) {
  const [field,
    // meta
  ] = useField("contentUrl");

  const { value } = field;
  // const { error, touched } = meta;

  const [previewImage] = useField("previewImage");

  return (
    <>
      <Row center>
        {previewImage.value || value !== "" ? (
          <>
            <PostImage src={previewImage.value ?? value} alt={""} />{" "}
            <button onClick={() => setField("previewImage", "")}>
              clear
            </button>
          </>
        ) : (
          <ImageUpload setField={setField} />
        )}
      </Row>
      {/* 
      <TextInput
        {...field}
        isTouched={touched}
        errorMessage={error}
        type="text"
        placeholder="Paste the image link"
      /> */}
      <Margin top={20} />
    </>
  );
}

function Description() {
  const [field] = useField("description");
  return (
    <S.DescriptionInput
      {...field}
      rows={1}
      placeholder="Add some description if you want"
    />
  );
}
