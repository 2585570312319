import { ReactElement, ReactNode } from "react";
import * as S from "./Padding.styled";

export interface PaddingProps {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
  all?: number;
  children: ReactNode;
}

export default function Padding({
  top,
  bottom,
  right,
  left,
  children,
}: PaddingProps): ReactElement {
  return (
    <S.Padding top={top} bottom={bottom} right={right} left={left}>
      {children}
    </S.Padding>
  );
}
