import * as S from "./TextInput.styled";

interface ErrorFieldProps {
  errorMessage: string;
}

export default function ErrorField({ errorMessage }: ErrorFieldProps) {
  return (
    <S.ErrorText>
      <small> {errorMessage} </small>
    </S.ErrorText>
  );
}
