import { ReactElement } from "react";
import * as S from "./ImageUpload.styled";

export type SetFieldFunction = (
  field: string,
  value: any,
  shouldValidate?: boolean
) => void;

function ImageUpload({
  setField,
}: {
  setField: SetFieldFunction;
}): ReactElement {
  function readImage(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setField("previewImage", reader.result);
      setField("uploadedImage", file);
    };
  }

  return (
    <S.ImageUpload>
      <input
        className="upload-button"
        id="file"
        name="uploadedImage"
        type="file"
        accept="image/jpeg, image/jpg, image/png"
        onChange={(event) => {
          event.currentTarget.files && readImage(event.currentTarget.files[0]);
          // event.currentTarget.files && setField("uploadedImage", event.currentTarget.files[0]);
        }}
      />
    </S.ImageUpload>
  );
}

export default ImageUpload;
