import { ReactNode } from "react";
import * as S from "./Card.style";

export interface CardProps {
  children?: ReactNode;
  padding?: string;
  backgroundColor?: string;
  borderColor?: string;
  onClick?: Function;
  hoverable?: boolean;
}

export default function Card({
  hoverable,
  children,
  backgroundColor,
  padding,
  onClick,
  borderColor,
}: CardProps) {
  return (
    <S.Card
      onClick={() => onClick && onClick()}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      padding={padding}
      hoverable={hoverable}
    >
      {children}
    </S.Card>
  );
}
