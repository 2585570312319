import { ReactElement, ReactNode } from "react";
import * as S from "./Margin.styled";

export interface MarginProps {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
  all?: number;
  children?: ReactNode;
}
export default function Margin({
  top,
  bottom,
  right,
  left,
  all,
  children,
}: MarginProps): ReactElement {
  return (
    <S.Margin top={top} bottom={bottom} right={right} left={left}>
      {children}
    </S.Margin>
  );
}
