import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Row from "../Row/Row.component";

import { ClickableIconProps } from "./ClickableIcon.component";

export const ClickableIcon = styled(Link) <ClickableIconProps>`
    border: none;
    text-decoration: none; 
    background-color: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.button.alternative.color};
    :hover {
        color: ${({ theme }) => theme.button.alternative.hover.color};
    }
`;

export const NotClickableIcon = styled(Row) <ClickableIconProps>`
    border: none;
    text-decoration: none; 
    background-color: transparent;
    color: ${({ theme }) => theme.button.alternative.color};

    ${({ onClick }: ClickableIconProps) =>
        onClick &&
        css`
     cursor: pointer; 
    `}

    
`;          