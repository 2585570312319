import { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import snarkdown from "snarkdown";
import backendApi from "../../api/backendApi";
import AppBar from "../../components/AppBar/AppBar.component";
import Card from "../../components/Card/Card.component";
import Divider from "../../components/Divider/Divider.component";
import Layout from "../../components/Layout/Layout.component";
import Margin from "../../components/Margin/Margin.component";
import Row from "../../components/Row/Row.component";
import Text from "../../components/Text/Text.component";
import buildUrlToFile from "../../helpers/buildUrlToFile";
import { NotificationContext } from "../../providers/NotificationProvider";
import { theme } from "../../Theme";
import { IArticle } from "../../types/Article.type";
import ArticleThumbnail from "../BlogPage/ArticleThumbnail";

export default function ArticlePage(): ReactElement {
  const [article, setArticle] = useState<IArticle>();
  const { showSnackbar } = useContext(NotificationContext);
  const { slug } = useParams<{ slug: string }>();

  useEffect(
    () => {
      async function fetchArticle() {
        try {
          const article = await backendApi.pocketBase.getArticleBySlug(slug);
          setArticle(article);
        } catch (error: any) {
          showSnackbar({ type: "error", children: error.message });
        }
      }

      fetchArticle();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slug]
  );

  return (
    <>
      <AppBar />
      <Layout>
        <Card>
          <div
            style={{ fontSize: "1.1em", fontFamily: "serif", padding: "10px" }}
          >
            <Row>
              <Text h1> {article?.title}</Text>
              {article && (
                <ArticleThumbnail
                  imageUrl={buildUrlToFile(
                    "articles",
                    article.id,
                    article.image
                  )}
                />
              )}
            </Row>
            <Text p color={theme.textStyles.fontColor.descriptionColor}>
              {article?.shortDescription}
            </Text>
            <Divider />
            <Margin top={40} />
            {article && (
              <div
                style={{
                  fontSize: "1.1em",
                  fontFamily: "serif",
                }}
                dangerouslySetInnerHTML={{ __html: snarkdown(article.content) }}
              />
            )}
          </div>
        </Card>
      </Layout>
    </>
  );
}
