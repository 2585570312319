import { ReactElement } from "react";
import * as S from "./ArticleThumbnail.styled";


export default function ArticleThumbnail({
  imageUrl,
  alt,
}: {
  imageUrl: string;
  alt?: string;
}): ReactElement {
  return <S.ArticleThumbnail src={imageUrl} alt={alt} />;
}
