import styled from "styled-components";

export const Img = styled.img`
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-width: 500px; 

    @media (max-width: ${(props) => props.theme.breakpoints.small}) {
        margin:0 -${(props) => props.theme.paddings.normal};
        width: 100vw;
    }
`;