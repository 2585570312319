import * as SC from "styled-components";

export const colors = {
  white: "#FFFFFF",
  dirtyWhite: "#dbdbdb",
  lightGrey: "#878787",
  grey: "#2d2d2d",
  black: "#000000",
  darkGrey: "#212121",
  blue: "#3F85B4",
  pink: "#f96d6d",
  lightPink: "#ffb7b6",
  darkOrange: "#be9500",
};

export const theme = {
  backgroundColor: colors.grey,
  errorColor: colors.darkOrange,
  navBar: {
    borderColor: colors.darkGrey,
    backgroundColor: colors.grey,
  },
  borderColors: {
    lightGrey: "#dfdfdf",
    grey: "#c7c7c7",
  },
  textInput: {
    border: {
      active: colors.darkGrey,
    },
  },
  card: {
    backgroundColor: colors.darkGrey,
    borderColor: colors.black,
    hover: {
      borderColor: colors.lightGrey,
    },
  },
  button: {
    primary: {
      backgroundColor: colors.pink,
      borderColor: colors.darkGrey,
    },
    alternative: {
      color: colors.lightGrey,
      hover: {
        color: colors.dirtyWhite,
      },
    },
  },
  link: {
    color: colors.pink,
  },
  paddings: {
    normal: "8px",
  },
  commentComposer: {
    borderColor: colors.lightGrey,
    backgroundColor: colors.darkGrey,
    color: colors.dirtyWhite,
  },
  textStyles: {
    fontFamily:
      " -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'," +
      "'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
    fontWeight: "",
    fontColor: {
      descriptionColor: colors.grey,
      default: colors.dirtyWhite,
    },
    // TODO: define these. until then use default
    fontSize: {
      h1: "2em",
      h2: "21px",
      h3: "19px",
      h4: "17px",
      p: "16px",
      small: "12px",
    },
  },
  breakpoints: {
    xsmall: "0px",
    small: "420px",
    medium: "768px",
    large: "992px",
    extraLarge: "1024px",
    bodyContent: "1110px",
    xxLarge: "1200px",
    xxxLarge: "1380px",
  },
};

export type Theme = typeof theme;

export type ThemeFn = (
  props: React.PropsWithRef<{
    theme: Theme;
  }>
) => any;

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = SC as SC.ThemedStyledComponentsModule<Theme>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
