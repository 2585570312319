import { ReactElement, useContext, useState } from "react";
import { AuthenticationContext } from "../../providers/AuthenticationProvider";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Row from "../../components/Row/Row.component";
import TextInput from "../../components/TextInput/TextInput.component";
import Button from "../../components/Button/Button.component";
import Margin from "../../components/Margin/Margin.component";
import Snackbar from "../../components/Snackbar/Snackbar.component";
import { AuthenticationFormProps } from "./RegistrationForm.component";
import { useHistory } from "react-router-dom";

export default function LoginForm({
  redirectTo,
}: AuthenticationFormProps): ReactElement {
  const [globalError, setGlobalError] = useState();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { closeModal, refreshAuthToken, login } = useContext(
    AuthenticationContext
  );
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Must be at least 8 characters"),
  });
  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async ({ email, password }) => {
          setLoading(true);
          try {
            await login(email, password);
            await refreshAuthToken();
            history?.push(redirectTo ?? "/");
            closeModal();
          } catch (error: any) {
            setGlobalError(error.message);
          }
          setLoading(false);
        }}
      >
        {({ errors, touched }) => (
          <Form style={{ width: "100%", maxWidth: "450px" }}>
            <Row spaceEvenly>
              <TextInput
                name="email"
                type="email"
                label="E-mail"
                placeholder="example@email.com"
                isTouched={touched.email}
                errorMessage={errors.email}
              />
            </Row>

            <Row spaceEvenly>
              <TextInput
                name="password"
                type="password"
                label="Password"
                placeholder="password"
                isTouched={touched.password}
                errorMessage={errors.password}
              />
            </Row>
            <Row>
              <Button
                fullWidth
                disabled={isLoading ? true : false}
                type="submit"
              >
                {isLoading ? "Loading" : "Submit"}
              </Button>
            </Row>
            <Margin top={20} />
          </Form>
        )}
      </Formik>
      <Snackbar
        isVisible={globalError!!}
        type="error"
        children={globalError}
        onClose={() => setGlobalError(undefined)}
      />
    </>
  );
}
