import * as S from "./PostImage.styled";

export interface PostImageProps {
  src: string;
  alt: string;
}

export default function PostImage({ src, alt }: PostImageProps) {
  return <S.Img src={src} alt={alt} />;
}
