import { ReactElement } from "react";
import UnstyledLink from "../Link/Link.component";
import Card from "../Card/Card.component";
import Row from "../Row/Row.component";
import PostImage from "./PostImage.component";
import Text from "../Text/Text.component";
import * as S from "./Post.styled";
import Divider from "../Divider/Divider.component";
import Margin from "../Margin/Margin.component";
import ClickableIcon from "../ClickableIcon/ClickableIcon.component";
import { MessageCircle as IconComment } from "react-feather";
import { IPost } from "../../types/Post.type";
import getBaseUrl from "../../api/util/getBaseUrl";

export interface PostProps extends IPost {
  linkTo?: string;
  description?: string;
  commentCount?: number;
  hoverable?: boolean;
}

export default function Post({
  id,
  title,
  contentUrl,
  file,
  author,
  description,
  votes,
  commentCount,
  hoverable,
  linkTo,
}: PostProps): ReactElement {
  const fileURL = `${getBaseUrl().pocketBase}/api/files/posts/${id}/${file}`;

  return (
    <>
      <Card hoverable={hoverable}>
        <UnstyledLink key={id} to={linkTo}>
          <Margin left={4}>
            <Text h2 topMargin={false}>
              {title}
            </Text>
          </Margin>
        </UnstyledLink>
        <UnstyledLink to={linkTo}>
          {/* <Row reverse>
          <Text small> posted by {author}</Text>
        </Row> */}

          {(contentUrl || file) && (
            <Row center>
              <PostImage
                src={contentUrl === "" ? fileURL : contentUrl}
                alt={title}
              />
            </Row>
          )}

          {description && (
            <S.DescriptionWrapper>
              <S.Description> {description} </S.Description>
            </S.DescriptionWrapper>
          )}
        </UnstyledLink>
        <Divider />
        <Row reverse>
          {/* <ClickableIcon onClick={() => {}} icon={<BookmarkIcon />} /> */}
          <CommentIcon linkTo={linkTo} commentCount={commentCount} />
          {/* <Votes postId={id!} votes={votes} /> */}
        </Row>
      </Card>
    </>
  );
}

interface CommentIconProps {
  linkTo?: string;
  commentCount?: number;
}

function CommentIcon({ linkTo, commentCount }: CommentIconProps) {
  return (
    <ClickableIcon to={linkTo} icon={<IconComment />}>
      {commentCount}
    </ClickableIcon>
  );
}
