import styled from "styled-components";

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: ${(props) => props.theme.paddings.normal};
  background-color: ${(props) => props.theme.commentComposer.backgroundColor};
  border: 1px solid ${(props) => props.theme.commentComposer.borderColor};
  color: ${(props) => props.theme.commentComposer.color};
  font-family: inherit;
  font-size: 16px;
`;
