import { ReactElement } from "react";
import Card from "../../components/Card/Card.component";
import Column from "../../components/Column/Column.component";
import { UnstyledLink } from "../../components/Link/Link.styled";
import Margin from "../../components/Margin/Margin.component";
import Row from "../../components/Row/Row.component";
import Text from "../../components/Text/Text.component";
import buildUrlToFile from "../../helpers/buildUrlToFile";
import { colors } from "../../Theme";
import { IArticle } from "../../types/Article.type";
import ArticleThumbnail from "./ArticleThumbnail";

function ArticlePreview({
  id,
  title,
  author,
  image,
  slug,
  shortDescription,
  created,
}: IArticle): ReactElement {
  return (
    <UnstyledLink to={`/blog/${slug}`}>
      <Card hoverable>
        <Row>
          <Column flex={3}>
            <Row spaceBetween>
              <Text small color={colors.grey}>
                {created && buildDate(created.toString())}
              </Text>
            </Row>
            <Text h3>{title}</Text>
            <Text
              topMargin={false}
              bottomMargin={false}
              p
              color={colors.grey}
            >
              {shortDescription}
            </Text>
          </Column>
          <Margin left={10} />
          <Column flex={1}>
            <ArticleThumbnail
              imageUrl={buildUrlToFile("articles", id, image)}
              alt=""
            />
          </Column>
        </Row>
      </Card>
    </UnstyledLink>
  );

  function buildDate(date: string): string {
    let yymmdd = date.split(" ")[0];
    yymmdd = yymmdd.replaceAll("-", "/");
    return yymmdd;
  }
}

export default ArticlePreview;
