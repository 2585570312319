import { ReactNode } from "react";
import * as S from "./Circle.styled";

export interface CircleProps {
  children?: ReactNode;
  color?: string;
  size?: string;
}

export default function Circle({
  size = "25px",
  children,
  color,
}: CircleProps) {
  return (
    <S.Circle size={size} color={color}>
      {children}
    </S.Circle>
  );
}
