import styled, { colors } from "../../Theme";

export const SideMenuContainer = styled.div`
  padding: 8px;
  display: block;
  top: 0;
  left: 0;
  position: fixed;
  width: 50%;
  height: 100vh;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.borderColors.lightGrey};
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #00000087;
  height: 100vh;
  width: 100vw;
`;

export const SideMenuSection = styled.div`
  text-transform: uppercase;
  color: ${(props) => colors.pink};
  font-weight: bold; 
`;
