import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { AuthenticationContext } from "../../providers/AuthenticationProvider";
import { Avatar } from "../Avatar/Avatar.component";
import * as S from "./ProfileButton.styled";
import { ChevronDown, LogOut } from "react-feather";
import Row from "../Row/Row.component";
import Padding from "../Padding/Padding.component";
import { NotificationContext } from "../../providers/NotificationProvider";
import { MenuItemProps } from "../MenuItem/MenuItem.component";
import DropdownMenu from "../DropdownMenu/DropdownMenu.component";
import Button from "../Button/Button.component";
import { Link } from "react-router-dom";

export default function ProfileButton(): ReactElement {
  const { user, logout } = useContext(AuthenticationContext);
  const { showSnackbar } = useContext(NotificationContext);
  const [isOpen, setOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClickMenuCloser(wrapperRef);

  const menuItems: Array<MenuItemProps> = [
    // { label: "create", linkTo: "/create", icon: <PlusCircle /> },
    {
      label: "logout",
      action: async () => {
        try {
          logout();
        } catch (error) {
          showSnackbar({
            type: "error",
            children: "Something went wrong",
            isVisible: true,
          });
        }
      },
      icon: <LogOut />,
    },
  ];

  function useOutsideClickMenuCloser(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeMenu();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function openMenu() {
    setOpen(true);
  }

  function closeMenu() {
    setOpen(false);
  }

  if (!user.isAuthenticated) {
    return <SignInButton />;
  }

  return (
    <S.ProfileButtonContainer
      ref={wrapperRef}
      onClick={() => (isOpen ? closeMenu() : openMenu())}
    >
      <S.ProfileButton>
        <Row>
          <Avatar
            src="https://static01.nyt.com/images/2016/09/28/us/17xp-pepethefrog_web1/28xp-pepefrog-articleLarge.jpg?quality=75&auto=webp&disable=upscale"
            text={user.username}
            alt={user.username}
          />
          <Padding left={8}>
            <ChevronDown width={15} height={15} />
          </Padding>
        </Row>
      </S.ProfileButton>
      {isOpen && <DropdownMenu menuItems={menuItems} />}
    </S.ProfileButtonContainer>
  );
}

function SignInButton() {
  return (
    <Link to="/login">
      <Button>
        <b> Sign in </b>
      </Button>
    </Link>
  );
}
