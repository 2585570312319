import styled from "styled-components";

export const ArticleThumbnail = styled.img`
  object-fit: cover;
  height: 100%;
  max-height: 150px;
  max-width: 200px;
  width: 100%;
  border-radius: 5px;
`;
