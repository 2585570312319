import AppRouter from "./router/Router";
import "./App.css";
import { AuthenticationContext } from "./providers/AuthenticationProvider";
import { useContext, useEffect } from "react";
import { NotificationProvider } from "./providers/NotificationProvider";

function App() {
  const { refreshAuthToken } = useContext(AuthenticationContext);

  /**
   * This check if user is already logged in when the app loads.
   * if yes a user object is set
   */
  useEffect(() => {
    refreshAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationProvider>
      <AppRouter />
    </NotificationProvider>
  );
}

export default App;
