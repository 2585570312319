import { ReactElement } from "react";
import { NoteProps } from "../Note/Note.component";
import Row from "../Row/Row.component";
import * as S from "./Snackbar.styled";
import { X } from "react-feather";

export interface SnackbarProps extends NoteProps {
  isVisible?: boolean;
  onClose?: Function;
}

export default function Snackbar({
  children,
  isVisible,
  type,
  onClose,
}: SnackbarProps): ReactElement {
  return !isVisible ? (
    <></>
  ) : (
    <S.Snackbar type={type}>
      <Row spaceBetween alignStart>
        {children}
        <S.CloseIcon onClick={() => onClose && onClose()}>
          <X />
        </S.CloseIcon>
      </Row>
    </S.Snackbar>
  );
}
