import styled, { css } from "styled-components";
import { ColumnProps } from "./Column.component";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100%;

  ${({ height }: ColumnProps) =>
    height &&
    css`
      height: ${height};
    `}

  ${({ spaceBetween }: ColumnProps) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}

  ${({ flex }: ColumnProps) =>
    flex &&
    css`
     flex: ${flex};
    `}

  
`;


