import { createContext, ReactNode, useState } from "react";
import backendApi from "../api/backendApi";
import SignUpModal, { ModalProps } from "../components/Modal/Modal.component";
import { IUser } from "../types/User.type";

interface AuthContextProps {
  user: IUser;
  refreshAuthToken: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  register: (
    email: string,
    password: string,
    passwordConfirm: string,
    username: string
  ) => Promise<any>;
  logout: () => Promise<void | undefined>;
  showSignUpModal: (modalInfo?: ModalProps | undefined) => void;
  closeModal: () => void;
}

export const AuthenticationContext = createContext({} as AuthContextProps);

interface AuthenticationProviderProps {
  children: ReactNode;
}

export default function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const [user, setUser] = useState({} as IUser);

  const [modalData, setModalData] = useState({
    backdropClick: closeModal,
  } as ModalProps);

  // Login updates the user data with a name parameter
  async function refreshAuthToken() {
    if (!user.isAuthenticated) {
      try {
        const authData = await backendApi.pocketBase.refreshAuthToken();

        if (authData) {
          setUser({
            username: authData.record.name ?? authData.record.username,
            id: authData.record.id,
            avatarUrl: authData.record.avatar,
            isAuthenticated: true,
          });
        } else {
          setUser({ isAuthenticated: false } as IUser);
        }
      } catch (error) {
        setUser({ isAuthenticated: false } as IUser);
      }
    }
  }

  async function login(email: string, password: string) {
    try {
      await backendApi.pocketBase.login(email, password);
    } catch (error) {
      throw error;
    }
  }

  async function register(
    email: string,
    password: string,
    passwordConfirm: string,
    username: string
  ): Promise<any> {
    try {
      var authData = await backendApi.pocketBase.register(
        email,
        password,
        passwordConfirm,
        username
      );
      return authData;
    } catch (error) {
      throw error;
    }
  }
  // Logout updates the user data to default
  async function logout() {
    try {
      backendApi.pocketBase.logout();
      setUser({ isAuthenticated: false } as IUser);
    } catch (error) {
      throw new Error("error when logging out");
    }
  }

  function showSignUpModal(modalInfo?: ModalProps) {
    setModalData({ ...modalData, ...modalInfo, isVisible: true });
  }

  function closeModal() {
    setModalData({ ...modalData, isVisible: false });
  }

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        refreshAuthToken,
        logout,
        showSignUpModal,
        login,
        closeModal,
        register,
      }}
    >
      {children}
      <SignUpModal
        isVisible={modalData.isVisible}
        backdropClick={modalData.backdropClick}
        title={modalData.title}
        description={modalData.description}
      />
    </AuthenticationContext.Provider>
  );
}
