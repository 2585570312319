import { ReactElement, ReactNode, useState } from "react";
import Card from "../Card/Card.component";
import Row from "../Row/Row.component";
import Text from "../Text/Text.component";
import ClickableIcon from "../ClickableIcon/ClickableIcon.component";
import CommentComposer, {
  CommentComposerInputValues,
  CommentComposerProps,
} from "../CommentComposer/CommentComposer.component";
import FormikSubmitHandler from "../../types/FormikSubmitHandler";
import Column from "../Column/Column.component";
import { ReplyButton } from "./ReplyButton.component";
import { IUser } from "../../types/User.type";
import { colors } from "../../Theme";
import Circle from "../Circle/Circle.component";
import Padding from "../Padding/Padding.component";

interface CommentProps extends CommentComposerProps {
  id: string;
  depth: number;
  content: string;
  author: IUser;
  onSubmit: FormikSubmitHandler<CommentComposerInputValues>;
  children?: ReactNode;
}

export default function Comment({
  content,
  onSubmit,
  children,
  disabled,
  name: formikFieldName,
  author,
  id,
  depth,
}: CommentProps): ReactElement {
  const [isVisible, setVisibility] = useState(false);

  return (
    <Card hoverable>
      <Row maxWidth spaceBetween>
        <Row>
          <Padding right={10}>
            <Circle size="20px" color={colors.lightPink} />
          </Padding>
          <Text color={colors.lightGrey} small>@{author.username} </Text>
        </Row>
        {depth < 3 && id && (
          <ClickableIcon
            hoverColor={"orange"}
            onClick={() => setVisibility(!isVisible)}
          >
            <ReplyButton />
          </ClickableIcon>
        )}
      </Row>
      <Row maxWidth>
        <Text> {content}</Text>
      </Row>

      {isVisible && (
        <CommentComposer
          active
          placeholder={"Write a reply..."}
          disabled={disabled}
          onSubmit={(val, ac) => {
            onSubmit(val, ac);
            setVisibility(false);
          }}
          onCancel={() => setVisibility(false)}
          name={formikFieldName}
        />
      )}
      <Column>{children}</Column>
    </Card>
  );
}
