import styled, { css } from "styled-components";
import * as S from "../TextInput/TextInput.styled";

const baseInputStyle = css`
  border: none;
  resize: none;
  outline: none;
  display: block;
  background-color: ${({ theme }) => theme.card.backgroundColor};
  color: ${({ theme }) => theme.textStyles.fontColor.default};
`;

export const TitleInput = styled(S.TextArea)`
  ${baseInputStyle}
  font-size: 24px;
  font-weight: bold;

  ::placeholder {
    font-weight: bold;
  }
`;

export const DescriptionInput = styled(S.TextArea)`
  ${baseInputStyle}
  font-size: 16px;
  border: 1px solid ${(props) => props.theme.borderColors.lightGrey};
  margin-bottom: 8px;
  font-weight: 300;
  text-align: center;

  ::placeholder {
    font-style: italic;
  }
`;
