import { ReactElement } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import AccountPage from "../pages/AccountPage/Account.page";
import BlogPage from "../pages/BlogPage/Blog.page";
import FeedPage from "../pages/FeedPage/Feed.page";
import PostPage from "../pages/PostPage/Post.page";
import PostCreationPage from "../pages/PostCreationPage/PostCreation.page";
import SignUpPage from "../pages/SignUpPage/SignUp.page";
import ArticlePage from "../pages/ArticlePage/Article.page";

export default function AppRouter(): ReactElement {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={"/"} exact component={FeedPage} />
        <Route path={"/account"} component={AccountPage} />
        <Route path={"/post/:postId"} component={PostPage} />
        <Route path={"/blog"} exact component={BlogPage} />
        <Route path={"/blog/:slug"} exact component={ArticlePage} />
        <Route path={"/create"} exact component={PostCreationPage} />
        <Route path={"/login"} exact component={SignUpPage} />
        <Route path={"/register"} exact component={SignUpPage} />
      </Switch>
    </BrowserRouter>
  );
}
