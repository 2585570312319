import styled from "../../Theme";

export const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  flex-grow: 1;
`;

export const TextInput = styled.input`
  border: 1px solid ${(props) => props.theme.borderColors.lightGrey};
  border-radius: 5px;
  padding: ${(props) => props.theme.paddings.normal};
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textStyles.fontColor.default};

  :active {
    border: 1px solid ${(props) => props.theme.textInput.border.active};
    background-color: ${({ theme }) => theme.backgroundColor};
  }
`;

export const TextArea = styled.textarea`
  border: 1px solid ${(props) => props.theme.borderColors.lightGrey};
  border-radius: 5px;
  padding: ${(props) => props.theme.paddings.normal};
  resize: none;
  min-height: 60px;
  font-family: ${(props) => props.theme.textStyles.fontFamily};
`;

export const TextInputLabel = styled.label`
  font-size: ${(props) => props.theme.textStyles.fontSize.h3};
  color: ${({ theme }) => theme.textStyles.fontColor.default};
  font-weight: bold;
  padding-bottom: 4px;
`;

export const TextInputHint = styled.p`
  font-size: ${(props) => props.theme.textStyles.fontSize.p};
  margin-block-start: 4px;
  margin-block-end: 4px;
`;

export const ErrorText = styled.div`
  display: flex;
  color: ${({ theme }) => theme.errorColor};
`;
