import styled from "styled-components";

export const AppBarContainer = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 40px;
  background-color: ${(props) => props.theme.backgroundColor};
  border-bottom: 1px solid ${(props) => props.theme.navBar.borderColor};
  z-index: 997;
  display: flex;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
    &.hidden {
      top: -6rem;
    }
  }
`;

export const AppBarWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-left: ${(props) => props.theme.paddings.normal};
  margin-right: ${(props) => props.theme.paddings.normal};
`;

export const AppBarSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  flex: 1;

  /* @media only screen and (max-width: ${(props) =>
    props.theme.breakpoints.medium}) {
    display: none;
  } */
`;
