import { ReactElement, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import * as S from "./AppBar.styled";
import Row from "../Row/Row.component";
import Logo from "./Logo.component";
import MenuItem, { MenuItemProps } from "../MenuItem/MenuItem.component";
import ProfileButton from "../ProfileButton/ProfileButton.component";
import useScrollDirection from "../../hooks/useScrollDirection";

interface Props {
  children?: ReactNode;
}

function AppBar({ children }: Props): ReactElement {
  const location = useLocation();
  const scrollDirection = useScrollDirection();

  const appBarElementsConfig: MenuItemProps[] = [
    // { linkTo: "/", label: "memes" },
  ];

  return (
    <S.AppBarContainer
      className={scrollDirection === "down" ? "hidden" : undefined}
    >
      <S.AppBarWrapper>
        <Row spaceBetween>
          <Logo linkTo="/" />
          <Row spaceBetween>
            <S.AppBarSection>
              {appBarElementsConfig.map(({ linkTo: path, label, icon }) => (
                <MenuItem
                  key={label}
                  icon={icon}
                  linkTo={path}
                  label={label}
                  active={path === location.pathname}
                />
              ))}
            </S.AppBarSection>
            <ProfileButton />
          </Row>
        </Row>
      </S.AppBarWrapper>
    </S.AppBarContainer>
  );
}

export default AppBar;
