export interface IBaseUrl {
    frontend?: string;
    backend?: string;
    pocketBase?:string; 
}

/**
* if NODE_ENV is "development" this will take the data from .env.development
* if NODE_ENV is "production" this will take the data from .env.production       
**/
function getBaseUrl(): IBaseUrl {

    let baseUrl = {
        frontend: process.env.REACT_APP_BASE_URL_FRONTEND,
        backend: process.env.REACT_APP_BASE_URL_BACKEND, 
        pocketBase: process.env.REACT_APP_BASE_URL_POCKETBASE, 
    }


    return baseUrl;

}

export default getBaseUrl