import { ReactElement, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppBar from "../../components/AppBar/AppBar.component";
import Card from "../../components/Card/Card.component";
import Divider from "../../components/Divider/Divider.component";
import Layout from "../../components/Layout/Layout.component";
import Margin from "../../components/Margin/Margin.component";
import Row from "../../components/Row/Row.component";
import Text from "../../components/Text/Text.component";
import searchParams from "../../constants/searchParams";
import { AuthenticationContext } from "../../providers/AuthenticationProvider";
import LoginForm from "./LoginForm.component";
import RegistrationForm, {
  AuthenticationFormProps,
} from "./RegistrationForm.component";

type AuthenticationPath = "/register" | "/login";

function SignUpPage(): ReactElement {
  const { user } = useContext(AuthenticationContext);
  const history = useHistory();
  const location = useLocation();
  const redirectTo = location.search.split(searchParams.redirectTo + "=")[1];
  const path: AuthenticationPath = location.pathname as AuthenticationPath;

  return user.isAuthenticated ? (
    <>{history.push("/")}</>
  ) : (
    <>
      <AppBar />
      <Layout>
        <Card>
          <Margin top={20} />
          {path === "/register" ? (
            <Registration redirectTo={redirectTo} />
          ) : (
            <Login redirectTo={redirectTo} />
          )}
        </Card>
      </Layout>
    </>
  );
}

export default SignUpPage;

function Registration({ redirectTo }: { redirectTo?: string }) {
  return (
    <>
      <Row center>
        <Text h1>Sign up</Text>
      </Row>
      <Row center>
        <RegistrationForm redirectTo={redirectTo} />
      </Row>
      <Divider />
      <p style={{ textAlign: "center" }}>
        Do you have an account already?{" "}
        <Link to="/login">
          <b>Login</b>
        </Link>
      </p>
    </>
  );
}

function Login({ redirectTo }: AuthenticationFormProps) {
  return (
    <>
      <Row center>
        <Text h1>Login</Text>
      </Row>
      <Row center>
        <LoginForm redirectTo={redirectTo} />
      </Row>
      <Divider />
      <p style={{ textAlign: "center" }}>
        Don't have an account yet?{" "}
        <Link to="/register">
          <b>Register</b>
        </Link>
      </p>
    </>
  );
}
