import { MouseEventHandler, ReactElement, ReactNode } from "react";
import Row from "../Row/Row.component";
import * as S from "./ClickableIcon.styled";

export interface ClickableIconProps {
  onClick?: MouseEventHandler;
  icon?: ReactNode;
  count?: number;
  hoverColor?: string;
  color?: string;
  children?: ReactNode;
  to?: string;
}

export default function ClickableIcon({
  onClick,
  icon,
  color,
  hoverColor,
  children,
  to,
}: ClickableIconProps): ReactElement {
  if (to) {
    return (
      <S.ClickableIcon
        to={to}
        onClick={onClick}
        hoverColor={hoverColor}
        color={color}
      >
        <Row>
          {icon} {children}
        </Row>
      </S.ClickableIcon>
    );
  } else {
    return (
      <S.NotClickableIcon onClick={onClick}>
        {icon} {children}
      </S.NotClickableIcon>
    );
  }
}
