import styled from "styled-components";

export const LogoWrapper = styled.div`
  max-width: 150px;
  max-height: 30px;
  display: flex;

  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
    display: none;
  }
`;

export const SmallLogoWrapper = styled.div`
  display: none;

  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
    display: flex;
    cursor: pointer;
  }
`;
