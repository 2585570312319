import { createGlobalStyle } from "styled-components";
import { Theme } from "./Theme";



export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
    
    body {
        background-color: ${(props) => props.theme.backgroundColor}; 
    }

    p {
        color: ${(props) => props.theme.textStyles.fontColor.default}; 
    }

    a {
        color: ${({ theme }) => theme.link.color}; 
    }

    

`; 