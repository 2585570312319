import { ReactElement, ReactNode } from "react";
import * as S from "./Link.styled";

interface UnstyledLinkProps {
  to?: string;
  children: ReactNode;
}

function UnstyledLink({ to, children }: UnstyledLinkProps): ReactElement {
  if (to) {
    return <S.UnstyledLink to={to}>{children}</S.UnstyledLink>;
  }

  return <> {children} </>;
}

export default UnstyledLink;
