import styled, { css } from "styled-components";
import { PaddingProps } from "./Padding.component";



export const Padding = styled.span<PaddingProps>`
    display: flex;
    ${({ all }) => all && css`
    padding:  ${all}px ${all}px ${all}px ${all}px; 
    `}

    ${({ top }) => top && css`
    padding-top:  ${top}px;
    `}

    ${({ bottom }) => bottom && css`
    padding-bottom:  ${bottom}px;
    `}

    ${({ left }) => left && css`
    padding-left:  ${left}px;
    `}

    ${({ right }) => right && css`
    padding-right:  ${right}px;
    `}

`;