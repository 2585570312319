import { ReactElement, useState } from "react";
import { UnstyledLink } from "../Link/Link.styled";
import { ReactComponent as PMLogo } from "../../static/svg/pm-logo.svg";
import { ReactComponent as PMLogoSmall } from "../../static/svg/pm-logo-small.svg";
// import { ReactComponent as HamburgerIcon } from "../../static/svg/pm-hamburger-icon.svg";
import * as S from "./Logo.styled";
// import Margin from "../Margin/Margin.component";
import SideMenu from "./SideMenu.component";

interface LogoProps {
  linkTo?: string;
}

export default function Logo({ linkTo }: LogoProps): ReactElement {
  const [isOpen, setOpen] = useState(false);

  // function openSideMenu() {
  //   setOpen(true);
  // }

  function closeSideMenu() {
    setOpen(false);
  }

  return (
    <>
      <S.LogoWrapper>
        <UnstyledLink to={linkTo ?? ""}>
          <S.LogoWrapper>
            <PMLogo />
          </S.LogoWrapper>
        </UnstyledLink>
      </S.LogoWrapper>
      <S.SmallLogoWrapper>
        {/* <Margin right={8}>
          <HamburgerIcon
            onClick={() => (isOpen ? closeSideMenu() : openSideMenu())}
          />
        </Margin> */}
        <UnstyledLink to={linkTo ?? ""}>
          <PMLogoSmall />
        </UnstyledLink>
      </S.SmallLogoWrapper>
      {isOpen && <SideMenu backdropClick={closeSideMenu} />}
    </>
  );
}
